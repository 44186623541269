<template>
	<div class="row offers mt-2">
		<b-col lg="12">
			<div class="card">
				<b-row align-h="center">
					<b-col lg="6">
						<div class="p-2">
							<div class="mt-2 text-center">Search Permission</div>
							<b-input-group class="mt-3">
								<b-form-input
									v-model="query_search"
									type="search"
									placeholder="Type to search"
									@change="setQuerySearch"
									trim
								></b-form-input>
								<b-input-group-append>
									<b-button type="submit" variant="primary" @click="asyncFind">Search</b-button>
									<b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
									<b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-col>
				</b-row>
				<div class="card-body">
					<b-row class="justify-content-end">
						<span class="pr-2">
							<router-link class="btn btn-sm btn-dark" :to="{name: PermissionCreatePath.name}">
                <em class="fa fa-plus"></em>
              </router-link>
						</span>
						<span>
							<button class="btn btn-sm btn-dark" @click="actionGetPermission">
								<em class="fa fa-refresh"></em>
							</button>
						</span>
						<label for="" class="col-sm-auto">Count </label>
						<strong class="col-sm-auto">{{ totalRows }}</strong>
					</b-row>
					<b-table
						sticky-header="500px"
						:items="items"
						:fields="fields"
						:busy="isLoading"
						:isLoading="isLoading"
						show-empty
						responsive="xl"
						class="mt-3"
						hover
					>
						<template v-slot:table-busy>
							<div class="text-center text-dark my-2">
								<b-spinner
									class="align-middle"
									variant="dark"
									type="grow"
									label="Loading .."
								>
								</b-spinner>
								Loading ..
							</div>
						</template>
						<template #cell(No)="row">
							{{ (currentPage - 1) * perPage + (row.index + 1) }}
						</template>
						<template #cell(name)="row">
							{{ row.item.name }}
						</template>
						<template #cell(description)="row">
							{{ row.item.description }}
						</template>
						<template #cell(action_edit)="row">
							<router-link class="btn btn-sm btn-default" :to="{name: PermissionEditPath.name, params: {id: row.item.id}}">
                <em class="fa fa-edit"></em>
              </router-link>
						</template>
					</b-table>
				</div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1">
					<b-col lg="3">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							aria-controls="my-table"
						/>
					</b-col>
				</b-row>
			</div>
		</b-col>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PermissionCreatePath, PermissionEditPath } from "../../router/account";
export default {
	name: "access-permission",
	data() {
		return {
			PermissionCreatePath,
			PermissionEditPath,
			currentPage: 1,
			perPage: 20,
			query_search: '',
			isLoadingSearch: false,
			fields: [
				{ key: "No", label: "No" },
				{
					key: "name",
					label: "Name",
				},
				{
					key: "description",
					label: "Description",
				},
				{ key: "action_edit", label: "Edit" },
			],
		};
	},
	computed: {
		...mapState({
			isLoading: (state) => state.accessPermission.isLoading,
			items: (state) => state.accessPermission.items,
			isError: (state) => state.accessPermission.isError,
			totalRows: (state) => state.accessPermission.totalRows,
		}),
	},
	mounted() {
		this.actionGetPermission();
	},
	watch: {
		currentPage: function() {
			this.actionGetPermission();
		},
	},
	methods: {
		...mapActions("accessPermission", ["fetchPermission"]),
		actionGetPermission() {
			if (this.query_search == "") {
				let payload = {
					page: this.currentPage,
					limit: this.perPage,
				};
				this.fetchPermission(payload);
			} else {
				this.asyncFind();
			}
		},
		...mapActions("accessPermission", {
			searchPermission: "searchPermission"
		}),
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
		asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				if (!query) {
					this.actionGetPermission();
					this.isLoadingSearch = false;
					return;
				}
				this.searchPermission({
					q: query,
					page: this.currentPage,
					limit: this.perPage,
				})
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
		},
		asyncClearFind() {
			this.query_search = "";
			this.actionGetPermission();
			this.isLoadingSearch = false;
		},
	},
};
</script>
